import { takeLatest, fork, put, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import * as AuthSession from "expo-auth-session";
import reactNativeFirebaseAnalytics from "@react-native-firebase/analytics";
import * as Amplitude from "@amplitude/analytics-react-native";
import Constants from "expo-constants";
import { Actions } from "react-native-router-flux";
import qs from "qs";
import amplitude from "amplitude-js";
import moment from "moment-mini";
import Purchases from "react-native-purchases";
import { isFunction } from "lodash";
import config from "../../../../config";
import { actions, constants, SOCIAL_LOGIN_SITES } from "./index";
import { actions as alertsActions } from "../../alerts";
import { actions as notificationsActions } from "../../notifications";
import {
  getPortfoliosListRoutine,
  clearPortfoliosList
} from "../../portfolios/index";
import ApiClient from "../../../../api/ApiClient";
import decisionModal from "../../../../lib/decisionModal";
import { getIsIos, getIsWeb, getSystem } from "../../../../lib/platformHelper";
import { UrlProvider } from "../../../../api/UrlProvider";
import { clearFollowedStocks, followedStocksRoutine } from "../../followed";
import { getPushNotificationsToken, getUserData, getUserID } from "./selector";
import { getLocale } from "../../translations/selector";
import { clearPaymentDetails, paymentDetailsRoutine } from "../../payment";
import { setAuthLoginLoading } from "../../authLoginLoading";
import { fetchMarketingBarsRoutine } from "../../marketingBars";
import AnalyticsEventTracker from "../../../../lib/AnalyticsEventTracker/";
import buildRedirectUrl from "../../../../lib/buildRedirectUrl";
import { showModal } from "../../../../lib/react-redux-modal-provider";
import { updateStocksListSource } from "../../tags";
import {
  clearMarketingMessages,
  fetchMarketingMessagesRoutine
} from "../../marketingMessages";
import { setUserDataUpdateError } from "../../status";
import { initTagsService } from "../../tagsService/saga";
import { getAllTags } from "../../tagsService/selector";
import { affiliate } from "../../affiliateProgram";
import { initTagsServiceRoutine } from "../../tagsService";
import TagsHelper from "../../../../lib/TagsHelper/TagsHelper";
import UserLoginEvent from "../../../../lib/SalesManago/events/UserLoginEvent";
import { trackSMEvent } from "../../salesManagoEventTracker";

let Cookies;

if (getIsWeb()) {
  Cookies = require("js-cookie");
}

function getUserAccessLevel(userData) {
  return userData.has_access ? "premium" : "freemium";
}

function* onLogin({ payload: { data, urlParams } }) {
  let requestData = {};
  data.forEach(field => {
    if (field.type !== "checkbox") {
      requestData[field.name] = field.value;
    } else {
      requestData[field.name] = Boolean(field.selected.length);
    }
  });

  let shouldJoinAccounts = false;

  const { search } = getIsWeb() ? window.location : {};
  const socialLoginData = search
    ? qs.parse(search.replace("?", ""))
    : urlParams || {};

  if (socialLoginData.access_token) {
    shouldJoinAccounts = true;
  }

  try {
    yield put(setAuthLoginLoading(true));
    const { data } = yield ApiClient.post({
      urlPath: shouldJoinAccounts ? "app.auth.socialJoin" : "app.auth.login",
      data: {
        ...requestData,
        ...socialLoginData
      }
    });

    const { token } = data;

    yield afterLogin(token);
  } catch (error) {
    console.log(error);
    const response = JSON.parse(error.request.response);
    yield put(actions.loginError());
    yield put(
      alertsActions.addErrorAlert({
        title: response.message
      })
    );
    yield put(setAuthLoginLoading(false));
  }
}

export function* afterLogin(
  token,
  afterRegister = false,
  cookieLogin = false,
  registerSource: string = null,
  displayUserNameAfterLogin: boolean = false,
  registrationMethod: string = null
) {
  let searchParams = {};
  const locale = yield select(getLocale);

  if (getIsWeb()) {
    const { location } = require("../../../configureStore").history;

    searchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });
  }

  yield put(actions.setAuthToken(token));
  yield initTagsService();

  const allTags = yield select(getAllTags);
  const wig20Tag = allTags.find(tag => TagsHelper.isDefaultTag(tag));
  const alertedTag = allTags.find(tag => TagsHelper.isAlertedTag(tag));

  const { data: userData } = yield ApiClient.get("app.auth.user");

  if (getIsWeb()) {
    if (window.gtag) {
      gtag("set", "user_data", {
        email: userData.email
      });
    }
  }

  const userHasAccess = userData.has_access;

  if (afterRegister) {
    AnalyticsEventTracker.trackRegisterConversion(
      registerSource,
      userData.id,
      registrationMethod
    );
  }

  yield put(actions.loginSuccess(userData));
  yield put(notificationsActions.fetchNotifications(false, token));
  yield put(followedStocksRoutine());
  yield put(getPortfoliosListRoutine());
  yield put(paymentDetailsRoutine());
  yield put(fetchMarketingBarsRoutine());
  yield put(fetchMarketingMessagesRoutine());
  yield put(setAuthLoginLoading(false));
  yield put(actions.setUserWasLoggedIn(true));
  if (!displayUserNameAfterLogin) {
    yield put(
      alertsActions.addSuccessAlert({
        title: "Login successful"
      })
    );
  } else {
    yield put(
      alertsActions.addSuccessAlert({
        title: "You are now logged in as %{userName}",
        translationVars: {
          userName: userData.username
        }
      })
    );
  }

  if (!afterRegister) {
    const smEvent = new UserLoginEvent({
      loginType: registrationMethod || "email",
      referrer: getIsWeb() ? document.referrer : null,
      redirectionAfterLogin: searchParams?.authRedirect || null
    });

    yield put(trackSMEvent(smEvent));
  }

  if (getIsWeb()) {
    Cookies.set(config.userIdCookieName, userData.id, {
      domain: config.cookieDomain
    });
    Cookies.set(config.authTokenCookieName, token, {
      domain: config.cookieDomain
    });

    if (searchParams.authRedirect) {
      yield put(actions.executeAuthRedirect(searchParams.authRedirect));
    } else if (!cookieLogin) {
      // Do not execute redirect if login was triggered by auth cookie
      let redirectUrl = UrlProvider.getUrl("fe.desktopPage", { locale });

      if (!userHasAccess) {
        redirectUrl = UrlProvider.getUrl("fe.desktopPageWithTagId", {
          locale,
          tagId: wig20Tag.id
        });
      }

      if (searchParams.redirectTo) {
        redirectUrl = searchParams.redirectTo;
      }

      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 1000);
    }

    if (cookieLogin && searchParams.auth_token_for_login) {
      let redirectUrl = window.location.pathname;

      yield put(push(redirectUrl));
    }
  } else {
    if (!userHasAccess || !alertedTag || !alertedTag.stocks.length) {
      yield put(updateStocksListSource(wig20Tag));
    } else if (alertedTag && alertedTag.stocks.length) {
      yield put(updateStocksListSource(alertedTag));
    }

    Amplitude.setUserId(String(userData.id));

    if (afterRegister) {
      showModal("IntroductionPageSheet");
    }

    try {
      yield reactNativeFirebaseAnalytics().setUserId(String(userData.id));
    } catch (error) {}
  }

  try {
    AnalyticsEventTracker.trackUserLogInEvent();
  } catch (e) {}
}

function* onCookieLogin({ payload: { token, indicateChangingAccount } }) {
  yield afterLogin(token, false, true, null, indicateChangingAccount);
}

function* onAutoLogin({ payload: { token } }) {
  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.auth.login",
      data: {
        access_token: token
      }
    });

    const { token } = data;

    yield afterLogin(token);
  } catch (error) {
    yield put(
      alertsActions.addErrorAlert({
        title: "Auto login failed"
      })
    );

    const locale = yield select(getLocale);
    yield put(push(UrlProvider.getUrl("fe.login", { locale })));
  }
}

function* onRefreshUserData({ payload = {} }) {
  const { skipAffiliation, skipSettingGtmUserData, callback } = payload;

  let shouldProceed = false;

  let userData = null;

  try {
    const { data: userDataResponse } = yield ApiClient.get({
      urlPath: "app.auth.user",
      skipAccessCheck: true
    });

    if (getIsWeb() && !skipSettingGtmUserData) {
      if (window.gtag) {
        gtag("set", "user_data", {
          email: userDataResponse.email
        });
      }
    }

    userData = userDataResponse;

    if (userData) {
      yield put(actions.loginSuccess(userData));
      yield put(actions.refreshUserData.success(true));

      if (!skipAffiliation) {
        yield put(affiliate());
      }

      if (callback && isFunction(callback)) {
        callback(userData);
      }
    }

    shouldProceed = true;
  } catch (error) {
    if (error && error.request) {
      const { status } = error.request;
      if (status === 401 || status === 403) {
        yield put(actions.logout());
        yield put(actions.refreshUserData.failure(false));
      } else if (status >= 500) {
        yield put(setUserDataUpdateError(true));
      }
    }

    if (callback && isFunction(callback)) {
      callback(null);
    }
  }

  if (!shouldProceed) {
    return;
  }

  const user_status = userData.has_access ? "premium" : "freemium";
  const registration_date = moment(userData.date_joined).format("DD-MM-YYYY");

  const {
    email_validated: confirmed,
    email_marketing: marketing_emails,
    electronic_communication
  } = userData;

  const userDataToApply = {
    user_status,
    registration_date,
    confirmed,
    marketing_emails,
    electronic_communication
  };

  yield put(actions.setUserWasLoggedIn(true));

  if (getIsWeb()) {
    const newAccessLevel = getUserAccessLevel(userData);
    const oldAccessLevel = localStorage.getItem(
      config.oldAccessLevelStorageKey
    );
    const paymentDetails = localStorage.getItem(
      config.paymentDetailsStorageKey
    );

    if (oldAccessLevel !== newAccessLevel && paymentDetails) {
      const paymentDetailsObject = JSON.parse(paymentDetails);

      AnalyticsEventTracker.trackPaymentConversion(paymentDetailsObject);

      localStorage.removeItem(config.paymentDetailsStorageKey);
    }

    localStorage.setItem(config.oldAccessLevelStorageKey, newAccessLevel);

    if (userData) {
      Cookies.set(config.userIdCookieName, userData.id, {
        domain: config.cookieDomain
      });

      amplitude.getInstance().setUserProperties(userDataToApply);
    }
  } else {
    if (userData) {
      try {
        const event = new Amplitude.Identify();

        for (let key in userDataToApply) {
          if (userDataToApply.hasOwnProperty(key)) {
            event.set(key, userDataToApply[key]);
          }
        }

        yield Amplitude.identify(event);
      } catch (error) {
        console.log("ERROR at Amplitude.setUserPropertiesAsync");
        console.log(error);
      }

      try {
        yield reactNativeFirebaseAnalytics().setUserId(String(userData.id));
      } catch (error) {}
    }
  }
}

function* onSocialLogin({ payload: { site } }) {
  if (!SOCIAL_LOGIN_SITES[site]) {
    throw new Error(
      `Site can be one of: [${Object.keys(SOCIAL_LOGIN_SITES)
        .map(item => `"${item}"`)
        .join(", ")}]. Received "${site}" instead`
    );
  }

  let socialLoginSite = SOCIAL_LOGIN_SITES[site];

  if (!getIsWeb()) {
    socialLoginSite = `${socialLoginSite}_native`;
  }

  try {
    const {
      data: { redirect_url: redirectUrl }
    } = yield ApiClient.get(`app.auth.${socialLoginSite}`);

    if (getIsWeb()) {
      window.location.href = redirectUrl;
    } else {
      const appScheme =
        Constants.expoConfig.scheme || config.expoSchemeFallback;

      const result = yield AuthSession.startAsync({
        authUrl: redirectUrl,
        returnUrl: `${appScheme}://expo-auth-session`,
        projectNameForProxy: config.expoProjectNameForProxy
      });

      if (result.type === "cancel" || result.type === "dismissed") {
        yield put(
          alertsActions.addInfoAlert({
            title: "Canceled"
          })
        );
      } else if (result.type === "success") {
        const { params } = result;

        const processedResult = {
          access_token: params.access_token,
          expires_in: params.expires_in,
          social_type_id: params.social_type_id,
          token_type: params.token_type,
          site
        };

        yield onExecuteSocialLogin({
          payload: { data: processedResult }
        });
      } else {
        yield put(
          alertsActions.addErrorAlert({
            title: "Unexpected error occurred. Please contact support."
          })
        );
      }
    }
  } catch (error) {
    let response = "";

    if (error?.request?.response) {
      response = JSON.parse(error.request.response);
    }

    if (response?.message) {
      yield put(
        alertsActions.addErrorAlert({
          title: response.message
        })
      );
    } else {
      alertsActions.addErrorAlert({
        title: "Unexpected error occurred. Please contact support."
      });
    }
  }
}

function* onExecuteSocialLogin({ payload: { data } }) {
  const locale = yield select(getLocale);

  const { site, social_type_id } = data;
  const registerSource = site === "FACEBOOK" ? "facebook" : "gmail";
  let socialLoginSource = registerSource;

  if (social_type_id === 5) {
    socialLoginSource = "apple";
  }

  try {
    const {
      data: { token }
    } = yield ApiClient.post({
      urlPath: "app.auth.socialLogin",
      data
    });

    yield afterLogin(token, false, false, null, false, socialLoginSource);
  } catch (error) {
    if (error?.request?.status === 500) {
      yield put(
        alertsActions.addErrorAlert({
          title:
            "Unexpected error occurred. Contact support for more information."
        })
      );

      return;
    }

    const response = error?.request?.response
      ? JSON.parse(error.request.response)
      : {};

    if (response.validation) {
      yield put(
        alertsActions.addErrorAlert({
          title: response.message
        })
      );

      return;
    }

    const decision = yield decisionModal(
      "SocialLoginModal",
      {
        title: "What would you like to do?",
        message:
          "You can join social account with existing account, or create a new one"
      },
      ["createNewAccount", "linkWithExistingAccount", "cancel"]
    );

    switch (decision) {
      case "createNewAccount": {
        try {
          const {
            data: { token }
          } = yield ApiClient.post({
            urlPath: "app.auth.socialRegister",
            data
          });

          yield afterLogin(token, true, false, "Social", false, registerSource);
        } catch (error) {
          if (error?.request?.response) {
            const response = JSON.parse(error.request.response);

            yield put(
              alertsActions.addErrorAlert({
                title: response.message
              })
            );
          }
        }
        break;
      }

      case "linkWithExistingAccount": {
        if (getIsWeb()) {
          yield put(
            push({
              pathname: UrlProvider.getUrl("fe.login", {
                locale
              }),
              search: window.location.search
            })
          );
        } else {
          Actions.profileLogin({ urlParams: data });
        }

        yield put(
          alertsActions.addInfoAlert({
            title: "Log in to your account to finish joining accounts"
          })
        );

        break;
      }

      case "cancel":
      default: {
        yield put(
          alertsActions.addInfoAlert({
            title: "Social login canceled"
          })
        );
        if (getIsWeb()) {
          yield put(push(UrlProvider.getUrl("fe.login", { locale })));
        }
      }
    }
  }
}

function* onExecuteSocialLoginV2({ payload: { data } }) {
  const socialLoginSource = "gmail";

  try {
    const {
      data: { token }
    } = yield ApiClient.post({
      urlPath: "app.auth.googleV2Login",
      data
    });

    yield afterLogin(token, false, false, null, false, socialLoginSource);
  } catch (error) {
    if (error?.request?.status === 500) {
      yield put(
        alertsActions.addErrorAlert({
          title:
            "Unexpected error occurred. Contact support for more information."
        })
      );

      return;
    }

    try {
      const {
        data: { token }
      } = yield ApiClient.post({
        urlPath: "app.auth.googleV2Register",
        data
      });

      yield afterLogin(
        token,
        true,
        false,
        socialLoginSource,
        false,
        socialLoginSource
      );
    } catch (e) {
      const response = error?.request?.response
        ? JSON.parse(error.request.response)
        : {};

      yield put(
        alertsActions.addErrorAlert({
          title:
            response?.message ||
            "Google sign-in failed. Contact support for more information."
        })
      );
    }
  }
}

function* onLogout() {
  const locale = yield select(getLocale);
  const pushNotificationsToken = yield select(getPushNotificationsToken);
  const userId = yield select(getUserID);

  const allTags = yield select(getAllTags);
  const wig20Tag = allTags.find(tag => TagsHelper.isDefaultTag(tag));
  yield put(updateStocksListSource(wig20Tag));

  if (getIsWeb()) {
    Cookies.remove(config.authTokenCookieName, {
      domain: config.cookieDomain
    });
    Cookies.remove(config.userIdCookieName, {
      domain: config.cookieDomain
    });

    amplitude.getInstance().clearUserProperties();
    amplitude.getInstance().setUserId(null);
  } else {
    yield unregisterFromPushNotifications({
      payload: {
        registrationId: pushNotificationsToken,
        userId
      }
    });

    try {
      Amplitude.setUserId(null);
      Amplitude.reset();
    } catch (error) {
      console.log(
        "ERROR at Amplitude.reset or Amplitude.setUserId"
      );
      console.log(error);
    }

    try {
      yield reactNativeFirebaseAnalytics().setUserId(null);
    } catch (error) {}

    if (getIsIos()) {
      try {
        yield Purchases.logOut();
      } catch (e) {}
    }
  }

  yield put(actions.logoutAfter());

  if (getIsWeb()) {
    setTimeout(() => {
      window.location.href = UrlProvider.getUrl("fe.login", { locale });
    }, 1000);
  }
}

function* onLogoutAfter() {
  yield put(notificationsActions.clearNotifications());
  yield put(clearFollowedStocks());
  yield put(clearPaymentDetails());
  yield put(clearPortfoliosList());
  yield put(clearMarketingMessages());
  yield put(initTagsServiceRoutine());
}

function* onRegisterForPushNotifications({ payload }) {
  const system = getSystem();
  const urlPath = `app.pushNotifications.${system}`;

  try {
    yield ApiClient.post({
      urlPath,
      data: {
        user: payload.userId,
        registration_id: payload.registrationId
      }
    });

    yield put(
      actions.registerForPushNotificationsSuccess(payload.registrationId)
    );
  } catch (error) {
    console.log(error.request);
  }
}

function* unregisterFromPushNotifications({ payload }) {
  const system = getSystem();

  const { registrationId, userId } = payload;

  if (!registrationId) {
    return;
  }

  const urlPath = `app.pushNotifications.${system}`;

  try {
    const response = yield ApiClient.delete({
      urlPath,
      data: {
        user: userId,
        registration_id: registrationId
      },
      skipAccessCheck: true
    });
  } catch (error) {
    console.log(error);
  }
}

function* onEditUserData({ payload }) {
  let requestData = {};

  payload.userData.forEach(field => {
    requestData[field.name] = field.value;
  });

  try {
    const { data } = yield ApiClient.put({
      urlPath: "app.auth.editUserData",
      data: requestData
    });

    yield put(actions.refreshUserData());
    yield put(
      alertsActions.addSuccessAlert({
        title: data.message
      })
    );
    payload.callback();
  } catch (error) {
    if (error.request) {
      const response = JSON.parse(error.request.response);
      yield put(actions.editUserDataError(response.validation));
      yield put(
        alertsActions.addErrorAlert({
          title: response.message
        })
      );
    }
  }
}

function* onEditUserPassword({ payload }) {
  let requestData = {};

  payload.formData.forEach(field => {
    requestData[field.name] = field.value;
  });

  try {
    const { data } = yield ApiClient.put({
      urlPath: "app.auth.editUserData",
      data: requestData
    });

    yield put(actions.refreshUserData());
    yield put(
      alertsActions.addSuccessAlert({
        title: data.message
      })
    );
    payload.form.reset();
  } catch (error) {
    if (error.request) {
      const response = JSON.parse(error.request.response);
      yield put(actions.editUserPasswordError(response.validation));
      yield put(
        alertsActions.addErrorAlert({
          title: response.message
        })
      );
    }
  }
}

function* onToggleMarketingEmails() {
  const { marketing_emails } = yield select(getUserData);

  try {
    const { data } = yield ApiClient.put({
      urlPath: "app.auth.editUserData",
      data: { marketing_emails }
    });

    yield put(
      alertsActions.addSuccessAlert({
        title: data.message
      })
    );
  } catch (error) {
    if (error.request) {
      const response = JSON.parse(error.request.response);
      yield put(
        alertsActions.addErrorAlert({
          title: response.message
        })
      );
    }
  }
}

function* executeAuthRedirect({ payload: { authRedirect } }) {
  const userData = yield select(getUserData);

  try {
    const { data } = yield ApiClient.get("app.auth.loginToken");

    const redirectUrl = buildRedirectUrl(
      authRedirect,
      data.login_token,
      userData.id
    );

    window.location.replace(redirectUrl);
  } catch (error) {
    console.log(error);
  }
}

function* watchAuthActions() {
  yield takeLatest(constants.LOGIN, onLogin);
  yield takeLatest(constants.SOCIAL_LOGIN, onSocialLogin);
  yield takeLatest(constants.EXECUTE_SOCIAL_LOGIN, onExecuteSocialLogin);
  yield takeLatest(constants.EXECUTE_SOCIAL_LOGIN_V2, onExecuteSocialLoginV2);
  yield takeLatest(
    constants.REGISTER_FOR_PUSH_NOTIFICATIONS,
    onRegisterForPushNotifications
  );
  yield takeLatest(actions.refreshUserData.TRIGGER, onRefreshUserData);
  yield takeLatest(constants.COOKIE_LOGIN, onCookieLogin);
  yield takeLatest(constants.AUTO_LOGIN, onAutoLogin);

  yield takeLatest(constants.LOGOUT, onLogout);
  yield takeLatest(constants.LOGOUT_AFTER, onLogoutAfter);

  yield takeLatest(constants.EDIT_USER_DATA, onEditUserData);
  yield takeLatest(constants.EDIT_USER_PASSWORD, onEditUserPassword);
  yield takeLatest(constants.TOGGLE_MARKETING_EMAILS, onToggleMarketingEmails);

  yield takeLatest(constants.EXECUTE_AUTH_REDIRECT, executeAuthRedirect);

  yield takeLatest(
    constants.UNREGISTER_FROM_PUSH_NOTIFICATIONS,
    unregisterFromPushNotifications
  );
}

export default [fork(watchAuthActions)];
